import React from "react";
import "./article-teaser.scss"
import { GatsbyImage } from "gatsby-plugin-image";
import {Link} from "gatsby";

const ArticleTeaser = (props) => {

  const imageData = props?.image;
  const imageAlt = props?.alt;

  return (
    <div className="article-teaser">
      <GatsbyImage alt={imageAlt} image={{...imageData}}/>
      <h3><Link to={props.link}><span>{props.title}</span></Link></h3>
    </div>
  )
};

export default ArticleTeaser;
