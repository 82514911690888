import React, {useContext} from "react";
import "./latest-posts.scss"
import {graphql, useStaticQuery} from "gatsby";
import ArticleTeaser from "../node/article-teaser";
import {SiteContext} from "../../context/site-context";
import ArrowRight from "../../assets/svg/icons-feather/arrow-right.svg"
import Button from "../../components/buttons/button"

const LatestPosts = () => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  const posts = useStaticQuery(graphql`
    query {
      drupal {
        nl: nodeQuery(filter: {conditions: [
        {field: "type", operator: EQUAL, value: "article"},
        {field: "langcode", operator: EQUAL, value: "nl"},
        {field: "status", operator: EQUAL, value: "1"},
        {field: "promote", operator: EQUAL, value: "1"}]},
        limit: 3,
        sort: {field: "created", direction: DESC}) {
          entities {
            ... on Drupal_NodeArticle {
              entityTranslation(language: NL) {
                entityId,
                entityLabel,
                entityUrl {
                  path
                },
                ... on Drupal_NodeArticle {
                  fieldImage {
                    ... on Drupal_FieldNodeArticleFieldImage {
                      url
                      alt  
                      imageGatsbyFile {
                        childImageSharp {
                          gatsbyImageData(
                              width: 400
                              height: 300
                              transformOptions: {fit: COVER, cropFocus: ATTENTION}
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        en: nodeQuery(filter: {conditions: [
        {field: "type", operator: EQUAL, value: "article"},
        {field: "langcode", operator: EQUAL, value: "en"},
        {field: "status", operator: EQUAL, value: "1"},
        {field: "promote", operator: EQUAL, value: "1"}]},
        limit: 3,
        sort: {field: "created", direction: DESC}) {
          entities {
            ... on Drupal_NodeArticle {
              entityTranslation(language: EN) {
                entityId,
                entityLabel,
                entityUrl {
                  path
                },
                ... on Drupal_NodeArticle {
                  fieldImage {
                    ... on Drupal_FieldNodeArticleFieldImage {
                      url
                      alt
                      imageGatsbyFile {
                        childImageSharp {
                          gatsbyImageData(
                              width: 400
                              height: 300
                              transformOptions: {fit: COVER, cropFocus: ATTENTION}
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`
  );

  let linkBlog = `/${i18next.language}/blog`;


  return (
    <div className="latest-posts">
      <div className="inner-content">
        <h2>{i18next.t('Highlighted blog posts')}</h2>
        {
          posts.drupal[i18next.language].entities.map((node) => {
            if (node.entityTranslation === null) { return '' }
            return (
              <ArticleTeaser
                key={node.entityTranslation.entityId}
                title={node.entityTranslation.entityLabel}
                image={node.entityTranslation.fieldImage.imageGatsbyFile.childImageSharp.gatsbyImageData}
                alt={node.entityTranslation.fieldImage.alt}
                link={node.entityTranslation.entityUrl.path}
              />
              )
            }
          )
        }
        <Button className="all-posts" link={linkBlog}><ArrowRight/>{i18next.t('See all posts')}</Button>
      </div>
    </div>
  )
};

export default LatestPosts;
