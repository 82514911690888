import React, {useContext} from "react";
import "./elearning.scss"
import Button from "../../components/buttons/button"
import ArrowRight from "../../assets/svg/icons-feather/arrow-right.svg"
import {SiteContext} from "../../context/site-context";
import Check from "../../assets/svg/icons-feather/check.svg";
import { ReactYouTubeLite } from 'react-youtube-lite';

const ELearning = () => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  return (
    <div className="elearning">
      <div className="inner-content">
        <div className="video">
          <ReactYouTubeLite url="https://www.youtube.com/embed/J79PpsIASvI"></ReactYouTubeLite>
        </div>
        <div className="text">
          <h2>{i18next.t('Coaching')}</h2>
          <p>
          {i18next.t('Kies uit life coaching, loopbaancoaching of leiderschapscoaching. Allemaal ondersteund met een online academie.')}
          </p>
          <ul className="checklist">
            <li><Check width="32" height="32"/>Zowel live als online mogelijk</li>
            <li><Check width="32" height="32"/>Blended traject met e-learning</li>
            <li><Check width="32" height="32"/>Op basis van wetenschappelijke inzichten</li>
            <li><Check width="32" height="32"/>Een mix van begrijpen en voelen</li>
            <li><Check width="32" height="32"/>Concrete handvatten om mee verder te kunnen</li>
            <li><Check width="32" height="32"/>Coachsessies van elk anderhalf uur</li>
          </ul>
          <Button link={`/${i18next.language}/diensten`}><ArrowRight />{i18next.t('Meer over mijn trajecten')}</Button>
        </div>
      </div>
    </div>
  )
};

export default ELearning;
