import React from "react";
import "./frontpage.scss";
import Layout from "../components/layout/layout";
import LatestPosts from "../components/blocks/latest-posts";
import HeaderImage from "../components/header/header-image";
import Button from "../components/buttons/button";
import ArrowRight from "../assets/svg/icons-feather/arrow-right.svg";
import Elearning from "../components/blocks/elearning";
import Book from "../components/blocks/book";
import Services from "../components/blocks/services";
import {StaticImage} from "gatsby-plugin-image";
import Reviews from "../components/blocks/reviews";

const DutchFrontpage = () => {

  return (
    <Layout frontpage={true}>

      <main>
        <section>

          <HeaderImage/>

          <div className="image-text">
            <div className="inner-content">
              <div className="text">
                <h2>Mijn aanpak</h2>
                <p>In een maatschappij waar zelfpromotie en assertiviteit hoog in het vaandel staan, kan het uitdagend zijn om jezelf te laten zien en je stem te laten horen als iemand die liever reflecteert en observeert. </p>
<p>Het gevoel van anders zijn en het idee dat je niet past in de samenleving kan soms overweldigend zijn. Maar onthoud dat zowel introversie als hoogbegaafdheid geen hindernissen zijn, maar juist bronnen van kracht.</p>
<p>Ik ben Karolien Koolhof, en ik begrijp als geen ander hoe het is om zowel introvert als hoogbegaafd te zijn. Ook ik heb moeten leren om de kracht van mijn eigen persoonlijkheid te ontdekken. Als gecertificeerd en gediplomeerd coach sta ik klaar om jou te helpen die kracht in jezelf te vinden.</p>
                <Button link="/nl/about"><ArrowRight width="32" height="32"/>Meer over mij</Button>
              </div>
              <div className="image">
                <StaticImage
                  alt="Karolien Koolhof"
                  src="../assets/images/karolien-presenteert-3.jpg"
                  placeholder="blurred"
                  layout="constrained"
                />
              </div>
            </div>
          </div>

          <div className="image-text alt">
            <div className="inner-content">
              <div className="text">
                <h2>Hoe introvert ben jij?</h2>
                <p>Heb je af en toe ruimte voor jezelf nodig om even op te laden? Geef je de voorkeur aan één op één contact boven groepen? En houd je ervan om dingen diep te doorgronden? Dan zou het zomaar kunnen dat jij hoog scoort op het persoonlijkheidskenmerk introversie.</p>
                <Button link="/nl/blog/ben-jij-een-introvert-doe-de-test"><ArrowRight width="32" height="32"/>Doe de test</Button>
              </div>
              <div className="image">
                <StaticImage
                  alt="Introvert persoon"
                  src="../assets/images/introvert-test.jpg"
                  placeholder="blurred"
                  layout="constrained"
                />
              </div>
            </div>
          </div>

          {/* The book */}
          <Book/>

          {/* E-learning */}
          <Elearning/>

          {/* 3 blokjes services */}
          <Services showTitle={true} padding={true}/>

          {/* 3 blokjes reviews */}
          <Reviews showTitle={true} padding={true}/>

          {/* 3 blokjes blog */}
          <LatestPosts/>

          <div className="image-text certified">
            <div className="inner-content">
              <div className="text">
                <div className="certified-title">Gecertificeerd / aangesloten bij:</div>
                <div className="certified-wrapper">
                  <a className="noloc" href="https://www.noloc.nl/leden/37479/2362">
                    <StaticImage
                      alt="NOLOC gecertificeerd"
                      src="../assets/images/noloc-badge.png"
                      placeholder="blurred"
                      layout="constrained"
                      transformOptions={{grayscale: "true"}}
                    />
                  </a>
                  <a className="coachfinder" href="https://www.coachfinder.nl/coach/karolien-koolhof/">
                    <StaticImage
                      alt="TMA gecertificeerd"
                      src="../assets/images/coachfinder-verified.png"
                      placeholder="blurred"
                      layout="constrained"
                      transformOptions={{grayscale: "true"}}
                    /></a>
                  <a className="tma" href="https://www.tma-methode.nl">
                    <StaticImage
                      alt="TMA gecertificeerd"
                      src="../assets/images/tma-certified-professional.png"
                      placeholder="blurred"
                      layout="constrained"
                      transformOptions={{grayscale: "true"}}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

        </section>
      </main>

    </Layout>
  )
};

export default DutchFrontpage;
