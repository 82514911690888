import React, {useContext, useEffect, useState} from "react";
import "./reviews.scss"
import {SiteContext} from "../../context/site-context";

const Reviews = (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;
  const [getChecked, setChecked] = useState(0);
  const [getTimerId, setTimerId] = useState(0);

  const { showTitle, padding } = props;
  const paddingClass = !padding ? 'no-padding' : '';

  const reviews = [
    {
      quote: '"Ondanks alle opleidingen en trainingen die ik eerder volgde, bleef ik steeds aanlopen tegen dezelfde situaties. Toen las ik in Managementboek Magazine een interview met Karolien over haar boek Introvert Leiderschap. Wat een herkenning. Ik las het boek en wist vanaf dat moment dat ik met haar nog eens aan mezelf wilde werken."',
      author: 'Diana, projectmanagement officer'
    },
    {
      quote: '"Karolien is in haar begeleiding, naast een fijn en integer mens,  ook nuchter, compassievol, en diepgaand in haar inzichten en reflectie. Ze heeft veel kennis van zaken, zowel op het gebied van introversie als breder op het gehele vlak van coaching, wat haar flexibel maakt in alles wat op tafel komt."',
      author: 'Maud, adviseur'
    },
    {
      quote: '"Karolien heeft me geholpen om nog duidelijker uit te spreken waar ik naartoe wil werken. Met haar kennis en ervaring sluit ze goed aan mij als persoon. Ze vraagt door en met behulp van haar gerichte opdrachten kon ik mijn plannen steeds verder uitwerken."',
      author: 'Hanneke, onderwijskundige'
    },
    {
      quote: '"Positief! Veel stof om te laten bezinken, heeft nieuwe inzichten gegeven, goed qua lengte, fijn dat het blended is, de tijd tussen onze gesprekken was precies goed. De begeleiding was persoonlijk, uitdagend en confronterend op een prettige manier."',
      author: 'Laura, consultant research & development'
    },
  ];

  useEffect(() => {
    const timerId = setTimeout(() => {
      let newIndex = getChecked + 1;
      if (newIndex >= reviews.length) {
        newIndex = 0;
      }
      setChecked(newIndex);
    }, 10000);
    setTimerId(timerId);
  }, [getChecked]);

  const handleChange = (event, index) => {
    clearTimeout(getTimerId);
    setChecked(index);
  };

  if (i18next.language ==='nl') {
    return (
      <div className={`reviews ${paddingClass}`}>
        <div className="inner-content">
          { showTitle &&
            <h2>Wat anderen zeggen</h2>
          }
          <div className="carrousel">

            {
              reviews.map(function (values, index) {
                return <input key={index} type="radio" onChange={(e) => handleChange(e, index)} name="slides" id={`radio-${index}`} checked={index === getChecked} />
              })
            }

            <ul className="slides">
              {
                reviews.map(function (values, index) {
                  return (
                    <li key={index} className="slide">
                      <q>{ values.quote }</q>
                      <span className="author">{ values.author }</span>
                    </li>
                  )
                })
              }
            </ul>

            <div className="slidesNavigation">

            {
              reviews.map(function (values, index) {
                return <label key={index} htmlFor={`radio-${index}`} id={`dotForRadio-${index}`}/>
              })
            }
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (i18next.language ==='en') {
    return (
      <></>
    )
  }

};

export default Reviews;
