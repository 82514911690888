import React, {useContext} from "react";
import "./book.scss"
import Button from "../../components/buttons/button"
import ArrowRight from "../../assets/svg/icons-feather/arrow-right.svg"
import {SiteContext} from "../../context/site-context";
import { Link }  from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

const Book = () => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  return (
    <div className="book">
      <div className="inner-content">
        <div className="text">
          <h2>Introvert leiderschap</h2>

          <p>Mijn boek Introvert Leiderschap gaat over anders kijken. Anders kijken naar leiderschap, maar ook naar introversie en diversiteit. Aan de hand van de ervaringen van dertig introverte leiders uit binnen- en buitenland bied ik introverten inzicht in hun unieke kracht en laat ik extraverten stilstaan bij misvattingen rond introversie en leiderschap.</p>
          <p>Introvert Leiderschap is geschreven voor mensen die hun introverte kwaliteiten willen inzetten om effectieve leiders te worden. Het biedt praktische tips en strategieën om jouw unieke introverte stijl te omarmen en te gebruiken om je doelen te bereiken. Of je nu een manager bent die meer uit je team wil halen, een ondernemer die zijn of haar bedrijf wil laten groeien of gewoon geïnteresseerd bent in persoonlijke ontwikkeling, Introvert Leiderschap biedt waardevolle inzichten en adviezen die je kunnen helpen om je volledige potentieel te benutten.</p>
          <Button link={`/${i18next.language}/boek-introvert-leiderschap-karolien-koolhof`}><ArrowRight />{i18next.t('Bekijk het boek')}</Button>
        </div>
        <div className="image">
          <Link to={`/${i18next.language}/boek-introvert-leiderschap-karolien-koolhof`}>
            <StaticImage
              alt="Boek Introvert Leiderschap van Karolien Koolhof"
              src="../../assets/images/boek-introvert-leiderschap-karolien-koolhof-3d.png"
              placeholder="blurred"
              layout="constrained"
            />
          </Link>
        </div>
      </div>
    </div>
  )
};

export default Book;
