import React, {useContext} from "react"
import "./header-image.scss"
import {SiteContext} from "../../context/site-context";
import { StaticImage } from "gatsby-plugin-image"
import ArrowRight from "../../assets/svg/icons-feather/arrow-right.svg";
import Button from "../buttons/button";

export default () => {

  const siteContext = useContext(SiteContext);

  if (siteContext.i18next.language === 'nl') {
    return (
      <>
        <div className="header-image">
          <div className="inner-content">
            <div className="image">
              <StaticImage
                alt="Karolien Koolhof"
                src="../../assets/images/karolien-koolhof-2.png"
                placeholder="blurred"
                layout="constrained"
                sizes="(max-width: 480px) 200px, 400px"
              />
            </div>
            <div className="text">
              <h2>Hoe kan ik je helpen?</h2>
              <ul>
                <li><ArrowRight/>Herken jij de kenmerken van <span className="pop">introversie</span> / <span className="pop">hoogbegaafdheid?</span></li>
                <li><ArrowRight/>Wil jij meer <span className="pop">zichtbaarheid</span> op jouw manier?</li>
                <li><ArrowRight/>Wil jij een <span className="pop">volgende stap</span> zetten, maar weet je niet goed hoe?</li>
              </ul>
              <Button link="https://booking.setmore.com/scheduleappointment/40d4bd8f-1787-4d9e-9d45-8ca74f4886a5/bookappointment"><ArrowRight width="32" height="32"/>Plan een vrijblijvend gesprek</Button>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (siteContext.i18next.language === 'en') {
    return (
      <>
        <div className="header-image">
          <div className="inner-content">
            <div className="image">
              <StaticImage
                alt="Karolien Koolhof"
                src="../../assets/images/karolien-koolhof-2.png"
                placeholder="blurred"
                layout="constrained"
                sizes="(max-width: 480px) 200px, 400px"
              />
            </div>
            <div className="text">
              <h2>How can I help?</h2>
              <ul>
                <li>Do you want to be more <span className="pop">visible</span> in a way that <span className="pop">suits you</span>?</li>
                <li>Do you want to find the <span className="pop">job</span> that fits your <span className="pop">personality</span>?</li>
                <li>Do you want <span className="pop">real connections</span> without losing all your <span className="pop">energy</span>?</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }


  return (
    <p>No header found.</p>
  );

}
